import * as React from "react"
import Layout from "../components/layout"
import { graphql } from "gatsby"

export default function Home(props) {
  const node  = props.data.contentfulPage;
  return (
    <main>
      <title>Dita Basu</title>
      <Layout page="home" content={node}></Layout>
    </main>
  );
}

export const query= graphql` 
                    query   {
                      contentfulPage(link: {eq: "home"}) {
                        maintext {
                          raw
                          references {
                            ... on ContentfulAsset {
                              contentful_id
                              gatsbyImageData
                            }
                          }
                        }
                      }
                        }
                    `